<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="man-title">
        <span>水表档案</span>
      </div>
      <div class="search">
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="水表编号">
            <el-select size="small" v-model="formInline.facilityId" clearable placeholder="请选择水表编号">
              <el-option
                v-for="item in sheshi"
                :label="item.name"
                :value="item.id"
                :key="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="用户编号">
            <el-select size="small" v-model="formInline.facilityId" clearable placeholder="请选择用户编号">
              <el-option
                v-for="item in sheshi"
                :label="item.name"
                :value="item.id"
                :key="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="用户名称">
            <el-select size="small" v-model="formInline.facilityId" clearable placeholder="请选择用户名称">
              <el-option
                v-for="item in sheshi"
                :label="item.name"
                :value="item.id"
                :key="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属区域">
            <el-select size="small" v-model="formInline.facilityId" clearable placeholder="请选择所属区域">
              <el-option
                v-for="item in sheshi"
                :label="item.name"
                :value="item.id"
                :key="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属册本">
            <el-select size="small" v-model="formInline.facilityId" clearable placeholder="请选择所属册本">
              <el-option
                v-for="item in sheshi"
                :label="item.name"
                :value="item.id"
                :key="item.id"></el-option>
            </el-select>
          </el-form-item>
         
          <el-form-item label="水表状态">
            <el-select size="small" v-model="formInline.facilityId" clearable placeholder="请选择水表状态">
              <el-option
                v-for="item in sheshi"
                :label="item.name"
                :value="item.id"
                :key="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button size="small" type="primary" @click="onSubmit">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="table-list">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column label="序号" type="index" width="50"></el-table-column>
          <el-table-column prop="waterMeterNo" label="水表编号"></el-table-column>
          <el-table-column prop="userName" label="用户名称"></el-table-column>
          <el-table-column prop="userPhone" label="用户手机"></el-table-column>
          <el-table-column prop="faultReport" label="故障上报"></el-table-column>
          <el-table-column prop="cycleReport" label="巡查上报"></el-table-column>
          <el-table-column prop="region" label="所属片区"></el-table-column>
          <el-table-column prop="zone" label="所属册本"></el-table-column>
          <el-table-column prop="meterReadMode" label="抄表方式"></el-table-column>
          <el-table-column prop="waterMeterStatus" label="水表状态"></el-table-column>
          <el-table-column prop="installPosition" label="安装位置"></el-table-column>
          <el-table-column prop="installTime" label="安装时间">
            <template slot-scope="scope">
              {{ scope.row.installTime ? dayjs(scope.row.installTime).format('YYYY-MM-DD HH:mm:ss'):'' }}
            </template>
          </el-table-column>
          <el-table-column prop="location" label="定位"></el-table-column>
          <el-table-column prop="qrcodeUrl" label="二维码">
            <template slot-scope="scope">
              <!-- <img :src="scope.row.qrcodeUrl" width="60"> -->
              <el-image 
                style="width: 100px; height: 100px"
                :src="scope.row.qrcodeUrl"
                :preview-src-list="[scope.row.qrcodeUrl]"
                >
              </el-image>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="180">
            <template slot-scope="scope">
              <el-dropdown placement="bottom-start" trigger="click">
                <span @click.stop="">
                  <span class="el-dropdown-link">
                    操作<i class="el-icon-arrow-down"></i>
                  </span>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item><div @click="details(scope.row)">详情</div></el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <page v-if="total!==0" :size="size" :page="page" :total="total" v-on:pageChange="pageChange"></page>
      </div>
    </div>
  </div>
</template>

<script>
import page from '@/components/page.vue'
import dayjs from 'dayjs';
export default {
  components: {
    page
  },
  data() {
    return {
      loading: false,
      size: 50,
      page: 1,
      total: 0,
      formInline: {},
      tableData: [
        {
            waterMeterNo: 'TK-0546',
            userName: '李瑞',
            userPhone: '18267951346',
            faultReport: '故障',
            cycleReport: '巡查',
            region: '城南',
            zone: 'B区',
            meterReadMode: '采集器',
            waterMeterStatus: '水表状态',
            installPosition: '安装位置',
            installTime: 1637900928533,
            location: '位置',
            qrcodeUrl: '',
        }
      ],
      sheshi: [],
      staffId: 0,
      roleId: 0
    }
  },
  methods: {
    dayjs,
    onOff(row) {
      this.$ajax.post("ruleStatus", {
        id: row.id,
        status: row.status == 0 ? 1 : 0
      }).then((res) => {
        this.loadTableData();
      });
    },
    onSubmit() {
      this.page = 1
      sessionStorage.setItem('currentPage', 1)
      this.loadTableData()
    },
    details(row) {
    //   this.$router.push({ name: 'faultReportDetail', params: {row: row}})
    },
    pageChange(val) {
      this.page = val
      sessionStorage.setItem('currentPage', val)
      this.loadTableData()
    },
    loadTableData() {
      this.loading = true
      this.$ajax.post('ruleQuery', {
        pageNum: this.page,
        pageSize: this.size,
      },{
        taskType: 0
      }).then(res => {
        this.loading = false
        this.tableData = res.data
        this.total = res.total
      }).catch(err=>{
        this.loading = false
      })
    },
    getLastPage() {
      if(sessionStorage.getItem('detail')) {
        this.page = Number(sessionStorage.getItem('currentPage'))
      }else {
        this.page = 1
        sessionStorage.removeItem('currentPage')
      }
    },
  },
  mounted() {
    this.getLastPage()
    // this.loadTableData()
    this.staffId = sessionStorage.getItem('id')
    this.roleId = sessionStorage.getItem('r')
  },
  destroyed() {
    sessionStorage.removeItem('detail')
  }
}
</script>

<style lang="less" scoped>
.el-dropdown-dis {
  color: #ccc;
  cursor: not-allowed;
}
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.btn {
  float: right;
}
.search {
  padding: 0 10px;
}
</style>